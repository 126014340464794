import domReady from '@roots/sage/client/dom-ready';
import Alpine from 'alpinejs';
import 'magnific-popup/dist/jquery.magnific-popup.js';

// Import AOS
import AOS from 'aos';
 

/**
 * Application entrypoint
 */
domReady(async () => {

  // AOS config
  AOS.init({
    // Global settings:
    disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
    
  
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 60, // offset (in px) from the original trigger point
    delay: 50, // values from 0 to 3000, with step 50ms
    duration: 750, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  });


/**
   * Oliver calendar fix
   */
document.addEventListener('click', (event) => {
  // calender_logic();

  calendarLogic();
});

function calendarLogic() {

  // var
  var currentDate = new Date();
  var currentHour = currentDate.getHours();
  var currentDay = currentDate.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
  var calendarWeeks = document.querySelectorAll('#ui-datepicker-div .ui-datepicker-calendar tbody > tr');
  console.log(currentDay);
  var calendarInput = document.querySelector("#field_pickup_date");
  if(calendarInput) {
      calendarInput.readOnly = true;
  }

  // Define packing days (Tuesday and Thursday)
  var packingDays = [2, 4]; // Tuesday is 2, Thursday is 4
  var pickupDays = [3, 5];

  // Define cutoff time (14:00 or 2:00 PM)
  var cutoffHour = 14;
  
  // Apply class to packing days
  var allDays = [];
  
  calendarWeeks.forEach((week, i) => {
    let days = week.querySelectorAll('td');

    days.forEach((day, index) => {

      if(day.innerHTML != '&nbsp;') {
        day.classList.add('day');
        
        if (index == 2 || index == 4) {
          day.classList.add('pickup-day');
        }
        
        if (index == 1 || index == 3) {
          day.classList.add('packing-day');
        }
        allDays.push(day);
      }
      
    });
  });
  

  // Pickup logic conditionals
  var postponePickup = false;
  // Orders made on a packing day
  if (packingDays.includes(currentDay) && currentHour >= cutoffHour || pickupDays.includes(currentDay)) {
    postponePickup = true;
  }
  
  var calendarDays = document.querySelectorAll('#ui-datepicker-div .ui-datepicker-calendar tbody tr > .day');
  
  var dateToday = document.querySelector('.ui-datepicker-today')
  // Orders made on day before packing day
  if (postponePickup) {
    if(document.querySelector('.ui-datepicker-today')) {
      dateToday.classList.remove("ui-datepicker-today");
      dateToday.classList.remove("ui-datepicker-days-cell-over");
        var pickupcounter = 0;
        for (let i = 0; i < calendarDays.length; i++) {
          const day = calendarDays[i];
          if (day.classList.contains('pickup-day') && !day.classList.contains('ui-state-disabled')) {
            pickupcounter++;
            if(pickupDays.includes(currentDay)) {
              day.classList.add("ui-datepicker-today");
              day.classList.add("ui-datepicker-days-cell-over");
              day.querySelector('a').classList.add("ui-state-hover");
              break;
            }
            if(pickupcounter == 2) {
              day.classList.add("ui-datepicker-today");
              day.classList.add("ui-datepicker-days-cell-over");
              day.querySelector('a').classList.add("ui-state-hover");
              break;
            }
        }
        day.classList.add('ui-datepicker-unselectable', 'ui-state-disabled');
        day.removeAttribute("data-handler");
        day.removeAttribute("data-event");
        day.removeAttribute("data-month");
        day.removeAttribute("data-year");
        if(day.querySelector("a")) {
          var aTagText = day.querySelector('a').innerHTML;
          day.querySelector('a').remove();
          var createSpan = document.createElement("span");
          var textSpan = document.createTextNode(aTagText);
          createSpan.classList.add("ui-state-default");
          createSpan.appendChild(textSpan);
          day.appendChild(createSpan);
        }
      }
    }
  } else {
    if(document.querySelector('.ui-datepicker-today')) {
      dateToday.classList.remove("ui-datepicker-today");
      dateToday.classList.remove("ui-datepicker-days-cell-over");
        for (let i = 0; i < calendarDays.length; i++) {
          const day = calendarDays[i];
          if (day.classList.contains('pickup-day') && !day.classList.contains('ui-state-disabled')) {
            day.classList.add("ui-datepicker-today");
            day.classList.add("ui-datepicker-days-cell-over");
            day.querySelector('a').classList.add("ui-state-hover");
            break; // Exit the loop
        }
        day.classList.add('ui-datepicker-unselectable', 'ui-state-disabled');
        day.removeAttribute("data-handler");
        day.removeAttribute("data-event");
        day.removeAttribute("data-month");
        day.removeAttribute("data-year");
        if(day.querySelector("a")) {
          var aTagText = day.querySelector('a').innerHTML;
          day.querySelector('a').remove();
          var createSpan = document.createElement("span");
          var textSpan = document.createTextNode(aTagText);
          createSpan.classList.add("ui-state-default");
          createSpan.appendChild(textSpan);
          day.appendChild(createSpan);
        }
      }
    }
  }

  // If pickup is postponed, block dates through until next pickup day
  
}

  window.Alpine = Alpine
 
  Alpine.start()


  const header = document.getElementById('header');
  const headerNav = document.querySelectorAll('header nav');
  const headerBrand = document.querySelector('header .brand');
  const hamburgerTrigger = document.querySelectorAll('.hamburger-trigger');
  const hamburgerMenu = document.getElementById('hamburger-menu');
  pageScroll();
  var formControls = document.querySelectorAll('.form-control');
  var firstChange = true;
  var prevAmount;

  formControls.forEach(function (element) {
    element.addEventListener('click', function () {
      // Store the previous value when the input field is focused
      prevAmount = this.value;
    });

    element.addEventListener('change', function () {
      var sack_artnr = this.getAttribute('name');
      var sack_varuslag = this.getAttribute('id');
      var sack_amount = this.value;

      // Check if it's not the first change, then trigger the click event
      if (!firstChange) {
        // Call a function to handle the new fields after a delay (adjust the delay time as needed)
        if (prevAmount == 0) {
          setTimeout(function () {
            addValues(sack_varuslag, sack_artnr, sack_amount);
          }, 500);
          document.querySelector('.frm_add_form_row.frm_button').click();
        } else {
          addValues(sack_varuslag, sack_artnr, sack_amount);
        }
      } else {
        addValues(sack_varuslag, sack_artnr, sack_amount);
      }
      // After the first change, set the flag to false
      firstChange = false;
    });
  });

  function addValues(sack_varuslag, sack_artnr, sack_amount) {
    var varuslagInputs = document.querySelectorAll('.varuslag-input input');
    var artnrInputs = document.querySelectorAll('.artnr-input input');
    var amountInputs = document.querySelectorAll('.antal-input input');
    var removeBtns = document.querySelectorAll('.frm_repeat_buttons .frm_remove_form_row.frm_button');

    var matchFound = false;

    for (var i = 0; i < varuslagInputs.length; i++) {
      if (varuslagInputs[i].value == sack_varuslag && artnrInputs[i].value == sack_artnr) {
        if (sack_amount == 0) {
          removeBtns[i].click();
        } else {
          amountInputs[i].value = sack_amount;
        }
        matchFound = true;
        break; // Exit the loop since a match is found
      }
    }

    if (!matchFound) {
      for (var i = 0; i < varuslagInputs.length; i++) {
        if (!varuslagInputs[i].value && !artnrInputs[i].value && !amountInputs[i].value) {
          varuslagInputs[i].value = sack_varuslag;
          artnrInputs[i].value = sack_artnr;
          amountInputs[i].value = sack_amount;
          break; // Exit the loop after populating the empty inputs
        }
      }
    }
  }


  var filterBtns = document.querySelectorAll('.filter-btn');

  filterBtns.forEach(function (element) {
    element.addEventListener('click', function () {
      // Remove 'checked' class from all buttons
      filterBtns.forEach(function (btn) {
        btn.classList.remove('checked');
      });
  
      // Add 'checked' class to the clicked button
      this.classList.add('checked');
      var getId = this.id.replace("filter-", "");
      
      // Get the parent div
      var parentDiv = document.getElementById('table-parent'); // Replace 'yourParentDivId' with the actual id of your parent div
      parentDiv.scrollTop = 0;
      // Iterate through all child elements of the parent div
      for (var i = 0; i < parentDiv.children.length; i++) {
        var childElement = parentDiv.children[i];
        if (getId === "alla") {
          childElement.style.display = childElement.tagName === 'DIV' ? 'block' : 'inline';
        } else {
          // Check if the current child element has the specified id
          if (childElement.id === getId) {
            // If it does, set its display property to 'block' or 'inline' depending on its type
            childElement.style.display = childElement.tagName === 'DIV' ? 'block' : 'inline';
          } else {
            // If it doesn't, set its display property to 'none'
            childElement.style.display = 'none';
          }
        }
      }

    });
  });

  var PrislistaBtns = document.querySelectorAll('.filter');
  var fabrikBtns = document.querySelectorAll('.filter-fabrik');

  if (PrislistaBtns.length) {
    var itemContainers = document.querySelectorAll('.item-container');
    var tableWrappers = document.querySelectorAll('.table-wrapper');

    itemContainers.forEach(function (item) {
      if (item.classList.contains("jordbrotippen") || item.id === "jordbrotippen") {
        item.closest(".table-wrapper").classList.add("jordbrotippen");
        if (item.id === "hanvedsmossen") {
          item.closest(".table-wrapper").classList.add("hanvedsmossen");
        }
      } else {
        item.closest(".table-wrapper").classList.add("hanvedsmossen");
      }
    });

    tableWrappers.forEach(function (wrapper) {
      if (wrapper.classList.contains("jordbrotippen") && wrapper.classList.contains("hanvedsmossen")) {
        wrapper.closest(".table").classList.add("jordbrotippen", "hanvedsmossen");
      } else {
        if (wrapper.classList.contains("jordbrotippen")) {
          wrapper.closest(".table").classList.add("jordbrotippen");
        } else {
          wrapper.closest(".table").classList.add("hanvedsmossen");
        }
      }
    });

    var itemContainers = document.querySelectorAll('.item-container');
    var tableWrappers = document.querySelectorAll('.table-wrapper');
    var tables = document.querySelectorAll('.table');

    fabrikBtns.forEach(function (btn) {
      btn.addEventListener('click', function () {
        fabrikBtns.forEach(function (btn) {
          btn.classList.remove('checked');
        });
    
        
        this.classList.add('checked');
        var getId = this.id.replace("fabrik-", "");
        
        var parentDiv = document.getElementById('table-parent');
        parentDiv.scrollTop = 0;

        var getFilterBtn = document.querySelector(".filter.checked");
        var getFilterId = getFilterBtn.id.replace("filter-", "");

        var factoryClass = getId + "-col";
        var getThisFactoryCols = document.querySelectorAll("." + factoryClass);

        var getFactoryCols = document.querySelectorAll(".fabrik-col");


        if (getId == "alla") {
          getFactoryCols.forEach(function (col) {
            col.style.display = "grid";
          });
        } else {
          getFactoryCols.forEach(function (col) {
            col.style.display = "none";
          });
          
          getThisFactoryCols.forEach(function (col) {
            col.style.display = "grid";
          });
        }

        tables.forEach(function (table) {
          if (table.classList.contains(getId) || table.id === getId) {
            if (table.id === getFilterId || getFilterId === "alla") {
              table.style.display = "block";
            } else {
              table.style.display = "none";
            }
          } else if (getId === "alla") {
            if (table.id === getFilterId || getFilterId === "alla") {
              table.style.display = "block";
            } else {
              table.style.display = "none";
            }
          } else {
            table.style.display = "none";
          }
        });

        tableWrappers.forEach(function (wrapper) {
          if (wrapper.classList.contains(getId) || wrapper.id === getId) {
            wrapper.style.display = "block";
          } else if (getId === "alla") {
            wrapper.style.display = "block";
          } else {
            wrapper.style.display = "none";
          }
        });

        itemContainers.forEach(function (item) {
          if (item.classList.contains(getId) || item.id === getId) {
            item.style.display = "grid";
          } else if (getId === "alla") {
            item.style.display = "grid";
          } else {
            item.style.display = "none";
          }
        });

      });
    });

    PrislistaBtns.forEach(function (btn) {
      btn.addEventListener('click', function () {
        PrislistaBtns.forEach(function (btn) {
          btn.classList.remove('checked');
        });
    
        this.classList.add('checked');
        var getId = this.id.replace("filter-", "");
        
        var parentDiv = document.getElementById('table-parent');
        parentDiv.scrollTop = 0;

        var getFabrikBtn = document.querySelector(".filter-fabrik.checked");
        var getFabrikId = getFabrikBtn.id.replace("fabrik-", "");

        tables.forEach(function (table) {
          if(table.id === getId) {
            if(table.classList.contains(getFabrikId) || getFabrikId === "alla") {
              table.style.display = "block";
            } else {
              table.style.display = "none";
            }
          } else if (getId === "alla") {
            if(table.classList.contains(getFabrikId) || getFabrikId === "alla") {
              table.style.display = "block";
            } else {
              table.style.display = "none";
            }
          } else {
            table.style.display = "none";
          }
        });

      });
    });
  }

  var tippningBtns = document.querySelectorAll('.filter-tip');
  var fabrikTitles = document.querySelectorAll('.fabrik-title');
  var prisFabrik = document.querySelectorAll('.pris');
  var titleheadline = document.querySelectorAll('.title-headline');

  tippningBtns.forEach(function (btn) {
    btn.addEventListener('click', function () {
      tippningBtns.forEach(function (btn) {
        btn.classList.remove('checked');
      });

      this.classList.add('checked');
      var getId = this.id.replace("fabrik-", "");

      var parentDiv = document.getElementById('table-parent');
      parentDiv.scrollTop = 0;
      
      fabrikTitles.forEach(function (title) {
        title.style.display = "none";
        if(title.classList.contains(getId)) {
          title.style.display = "block";
        }
        if (getId === "alla") {
          title.style.display = "block";
        }

      });
console.log(getId);

      titleheadline.forEach(function (headline) {
        headline.style.display = "none";
        if(headline.classList.contains(getId + "-headline")) {
          if (jQuery(window).width() < 768) {
            headline.style.display = "block";
         }
        }
        if (getId === "alla") {
          if (jQuery(window).width() < 768) {
            headline.style.display = "block";
         }
        }

      });


      prisFabrik.forEach(function (pris) {
        pris.style.display = "none";
        if(pris.classList.contains(getId + "-pris")) {
          

          if (pris.textContent !== " Kr/ton") {
            pris.style.display = "block";
            pris.closest(".item-container").style.display = "grid";
            if (!pris.querySelector('span')) {
              pris.closest(".item-container").style.display = "none";
            }
          } else {
            pris.style.display = "none";
            pris.closest(".item-container").style.display = "none";
          }
        }
        if (getId === "alla") {
          pris.style.display = "block";
          pris.closest(".item-container").style.display = "grid";
        }

      });

    });
  });





  var fabrikSlap = document.querySelectorAll('.filter-slap');
  var prisFabrik = document.querySelectorAll('.pris');
  var itemContainers = document.querySelectorAll('.item-container');
  var tableWrappers = document.querySelectorAll('.table-wrapper');

  itemContainers.forEach(function (item) {
    if (item.classList.contains("jordbrotippen") || item.id === "jordbrotippen") {
      item.closest(".table-wrapper").classList.add("jordbrotippen");
      if (item.id === "hanvedsmossen") {
        item.closest(".table-wrapper").classList.add("hanvedsmossen");
      }
    } else {
      if(item.closest(".table-wrapper")) {
        item.closest(".table-wrapper").classList.add("hanvedsmossen");
      }
    }
  });

  tableWrappers.forEach(function (wrapper) {
    if (wrapper.classList.contains("jordbrotippen") && wrapper.classList.contains("hanvedsmossen")) {
      wrapper.closest(".table").classList.add("jordbrotippen", "hanvedsmossen");
    } else {
      if (wrapper.classList.contains("jordbrotippen")) {
        wrapper.closest(".table").classList.add("jordbrotippen");
      } else {
        wrapper.closest(".table").classList.add("hanvedsmossen");
      }
    }
  });

  fabrikSlap.forEach(function (btn) {
    btn.addEventListener('click', function () {
      fabrikSlap.forEach(function (btn) {
        btn.classList.remove('checked');
      });

      this.classList.add('checked');
      var getId = this.id.replace("fabrik-", "");

      var parentDiv = document.getElementById('table-parent');
      parentDiv.scrollTop = 0;

      var factoryClass = getId + "-col";
      var getThisFactoryCols = document.querySelectorAll("." + factoryClass);

      var getFactoryCols = document.querySelectorAll(".fabrik-col");


      if (getId == "alla") {
        getFactoryCols.forEach(function (col) {
          col.style.display = "grid";
        });
      } else {
        getFactoryCols.forEach(function (col) {
          col.style.display = "none";
        });
        
        getThisFactoryCols.forEach(function (col) {
          col.style.display = "grid";
        });
      }

      tableWrappers.forEach(function (wrapper) {
        if (wrapper.classList.contains(getId) || wrapper.id === getId) {
          wrapper.style.display = "block";
          wrapper.closest(".table").style.display = "block";
        } else if (getId === "alla") {
          wrapper.style.display = "block";
          wrapper.closest(".table").style.display = "block";
        } else {
          wrapper.style.display = "none";
          wrapper.closest(".table").style.display = "none";
        }
      });

      itemContainers.forEach(function (item) {
        if (item.classList.contains(getId) || item.id === getId) {
          item.style.display = "grid";
        } else if (getId === "alla") {
          item.style.display = "grid";
        } else {
          item.style.display = "none";
        }
      });

    });
  });


/**
 * Event listeners
 */
addEventListener('scroll', (event) => {
  pageScroll();
});

const headerBtnUl = document.querySelectorAll('.sub-menu');

headerBtnUl.forEach((el, i) => {
  el.addEventListener('mouseover', (event) => {
    el.closest("li").classList.add("hover");
  });
  el.addEventListener('mouseout', (event) => {
    el.closest("li").classList.remove("hover");
  });
});


const popupCancel = document.getElementById('close-popup');
const popupBlock = document.getElementById('exit-popup');
if(document.getElementById('modal')) {
  if (sessionStorage.getItem("key") !== "shown") {
    // Save data to sessionStorage
    sessionStorage.setItem("key", "shown");
  } else {
      document.getElementById('modal').classList.add('hidden');
    }
}


if(document.getElementById('modal-block')) {
  if (sessionStorage.getItem("key1") !== "shown-block") {
    // Save data to sessionStorage
    sessionStorage.setItem("key1", "shown-block");
  } else {
      document.getElementById('modal-block').classList.add('hidden');
    }
}

if(popupCancel) {
  popupCancel.addEventListener('click', (event) => {
    if(document.getElementById('modal')) {
      document.getElementById('modal').classList.add('hidden');
    }
  });
}

if(popupBlock) {
  popupBlock.addEventListener('click', (event) => {
    if(document.getElementById('modal-block')) {
      document.getElementById('modal-block').classList.add('hidden');
    }
  });
}



  // Hamburger menu
  hamburgerTrigger.forEach((el, i) => {
    el.addEventListener('click', (event) => {
      el.classList.toggle('open');
      hamburgerMenu.classList.toggle('open');
      
      header.classList.toggle('nav-open');
      headerBrand.classList.toggle('nav-open');
      
      headerNav.forEach((el, i) => {
        el.classList.toggle('nav-open');
      });
    });
  });

  // Header on scroll
  function pageScroll() {
    let scrollDistance = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    
    if (scrollDistance > 10) {
      header.classList.add('has-scrolled');
      headerBrand.classList.add('has-scrolled');

      headerNav.forEach((el, i) => {
        el.classList.add('has-scrolled');
      });

      return;
    }
    
    header.classList.remove('has-scrolled');
    headerBrand.classList.remove('has-scrolled');

    headerNav.forEach((el, i) => {
      el.classList.remove('has-scrolled');
    });
    
    return;
  }

  // This will create a single gallery from all elements that have class "gallery-item"
jQuery('.gallery-item').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery:{
    enabled:true
  }
});

if (document.body.classList.contains('anmal-massor')) {
  const iframe = document.getElementById('avfallskarta');
  
  var adressField = document.getElementById('field_address');
  var zipcodeField = document.getElementById('field_zipcode');
  var streetnrField = document.getElementById('field_street-number');
  var districtField = document.getElementById('field_location');
  
  // Function to send a message to the iframe
  function sendMessage(e) {
    
    var adress = adressField.value;
    var zipcode = zipcodeField.value;
    var streetnr = streetnrField.value;
    var district = districtField.value;
    
    if (adress && zipcode && streetnr && district) {
      const fullAdress = `${adress} ${streetnr} ${zipcode.replace(/\s/g, '')} ${district}`;
      
      iframe.contentWindow.postMessage({
        address: fullAdress,
        type: "search"
      }, 'https://avfallskarta.swescan.se/');
    }
  
  }

  window.onload = function() {
    sendMessage();
  };
  
  // Add 'input' event listeners to all fields to monitor changes dynamically
  [adressField, zipcodeField, streetnrField, districtField].forEach(field => {
    field.addEventListener('input', sendMessage);
  });
  
  
  
  window.addEventListener('message', event => {
      if (event.origin !== "https://avfallskarta.swescan.se") {
      // Ensure the message is from the expected origin for security reasons
      return;
    }

    var adress = document.getElementById('field_address').value;
    var zipcode = document.getElementById('field_zipcode').value;
    var streetnr = document.getElementById('field_street-number').value;
    var district = document.getElementById('field_location').value;
    var hiddenField = document.getElementById('field_map-info');
    hiddenField.value = `<strong>Projektets plats:</strong> ${adress}, ${streetnr}, ${zipcode.replace(/\s/g, '')}, ${district}\n\n`;
    hiddenField.value += `<strong>Kartpunktens plats:</strong> ${event.data['address']['city']} ${event.data['address']['county']} ${event.data['address']['municipality']} ${event.data['address']['postalCode']}\n\n`;
  
    
    event.data['analyse_packages'].forEach((data) => {
        var area = data['description'];
        var radius = data['distance'];
        var analysis = data['analysisPackage'];
        var price = data['price'];
        hiddenField.value += `<strong>Områdestyp:</strong> ${area} | <strong>Inom radie:</strong> ${radius}m | <strong>Analyspaket:</strong> ${analysis} | <strong>Pris:</strong> ${price} kr\n`;
          
        return hiddenField.value;
    });
    
  });
  
  var submitFormButton = document.getElementById('submitanmalan');
  
  if(submitFormButton) {
    submitFormButton.addEventListener('click', function (e) {
      e.preventDefault();
      var confirm_popup = document.getElementById('confirm_popup');
      
      confirm_popup.classList.toggle('hidden');
  
    });
  }
}

if (document.body.classList.contains('snabbanmalan')) {
  const iframe = document.getElementById('avfallskarta');
  
  var adressField = document.getElementById('field_address2');
  var zipcodeField = document.getElementById('field_zipcode2');
  var streetnrField = document.getElementById('field_street-number2');
  var districtField = document.getElementById('field_location2');
  
  // Function to send a message to the iframe
  function sendMessage(e) {
    
    var adress = adressField.value;
    var zipcode = zipcodeField.value;
    var streetnr = streetnrField.value;
    var district = districtField.value;
    
    if (adress && zipcode && streetnr && district) {
      const fullAdress = `${adress} ${streetnr} ${zipcode.replace(/\s/g, '')} ${district}`;
      
      iframe.contentWindow.postMessage({
        address: fullAdress,
        type: "search"
      }, 'https://avfallskarta.swescan.se/');
    }
  
  }
  
  window.onload = function() {
    sendMessage();
  };

  // Add 'input' event listeners to all fields to monitor changes dynamically
  [adressField, zipcodeField, streetnrField, districtField].forEach(field => {
    field.addEventListener('input', sendMessage);
  });
  
  
  
  window.addEventListener('message', event => {
      if (event.origin !== "https://avfallskarta.swescan.se") {
      // Ensure the message is from the expected origin for security reasons
      return;
    }
    
    var adress = document.getElementById('field_address2').value;
    var zipcode = document.getElementById('field_zipcode2').value;
    var streetnr = document.getElementById('field_street-number2').value;
    var district = document.getElementById('field_location2').value;
    var hiddenField = document.getElementById('field_map-info2');
    hiddenField.value = `<strong>Projektets plats:</strong> ${adress}, ${streetnr}, ${zipcode.replace(/\s/g, '')}, ${district}\n\n`;
    hiddenField.value += `<strong>Kartpunktens plats:</strong> ${event.data['address']['city']} ${event.data['address']['county']} ${event.data['address']['municipality']} ${event.data['address']['postalCode']}\n\n`;

    event.data['analyse_packages'].forEach((data) => {
        var area = data['description'];
        var radius = data['distance'];
        var analysis = data['analysisPackage'];
        var price = data['price'];
        hiddenField.value += `<strong>Områdestyp:</strong> ${area} | <strong>Inom radie:</strong> ${radius}m | <strong>Analyspaket:</strong> ${analysis} | <strong>Pris:</strong> ${price} kr\n`;
          
        return hiddenField.value;
    });
  });
  
  var submitFormButton = document.getElementById('submitanmalan');
  
  if(submitFormButton) {
    submitFormButton.addEventListener('click', function (e) {
      e.preventDefault();
      var confirm_popup = document.getElementById('confirm_popup');
      
      confirm_popup.classList.toggle('hidden');
  
    });
  }
}


});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);